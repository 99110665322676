<template>
  <with-side-navigations
    :view-width="50"
    :side-bar-width="50"
    side="right"
    footer
  >
    <template #sideNavigation>
      <div>
        <tabs>
          <tab :name="$t('add-client-view.documents')" selected>
            <file-uploader
              type="document"
              target="clients"
              :list="uploadQueue"
            />
          </tab>
        </tabs>
      </div>
    </template>
    <div>
      <breadcrumbs
        :path="[
          {
            name: `${$t('add-client-view.clients')}`,
            url: { name: 'Clients' },
          },
          { name: `${$t('add-client-view.add-client')}` },
        ]"
      />
      <tabs class="mt-4.5">
        <tab :name="$t('add-client-view.add-client')" selected class="pb-24">
          <client-form ref="form" @updateLoading="updateLoading" />
        </tab>
      </tabs>
    </div>
    <template #footer>
      <div class="flex justify-between">
        <div class="flex items-center">
          <btn
            :disabled="loading"
            :is-loading="loading"
            class="md:w-48"
            @click="clickSave"
          >
            <span v-if="!loading">{{ $t('common.save') }}</span>
          </btn>
          <btn
            :disabled="loading"
            :is-loading="loading"
            class="w-50 md:w-auto mx-3"
            theme="secondary"
            @click="clickSaveAndNext"
          >
            <span v-if="!loading">
              {{ $t('client-form.save-and-add-another') }}
            </span>
          </btn>
        </div>
        <router-link :to="{ name: 'Clients' }">
          <btn theme="none">{{ $t('common.cancel') }}</btn>
        </router-link>
      </div>
    </template>
  </with-side-navigations>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import ClientForm from '@/views/Dashboard/Clients/Forms/ClientForm.vue';
import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import WithSideNavigations from '@/layouts/WithSideNavigations.vue';
import FileUploader from '@/components/Uploader/FileUploader.vue';
import VueStore from '@/store';
import { mapState } from 'vuex';

export default {
  name: 'AddClient',
  components: {
    Breadcrumbs,
    Btn,
    ClientForm,
    Tabs,
    Tab,
    WithSideNavigations,
    FileUploader,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('attachment', ['uploadQueue']),
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      VueStore.dispatch('attachment/clearAttachmentList'),
      VueStore.dispatch('attachment/clearUploadQueue'),
      VueStore.dispatch('attachment/clearDeleteQueue'),
    ]);

    next();
  },
  methods: {
    clickSave() {
      this.$refs.form.handleSave();
    },
    clickSaveAndNext() {
      this.$refs.form.handleSaveAndNext();
    },
    updateLoading(value) {
      this.loading = value;
    },
  },
};
</script>

<style scoped>
.toggle-label {
  @apply flex items-center;
}
</style>
